import React from 'react';
import CloseIcon from './CloseIcon';

const Modal = ({ message, title }) => (
  <div className="modal">
    <input type="checkbox" className="modal--open" id="modal-1"/>
    <div className="modal--wrap" aria-hidden="true">
      <label className="modal--overlay" htmlFor="modal-1"/>
      <div className="modal--dialog">
        <div className="modal--header">
          <h2>{title}</h2>
          <label className="button--close" htmlFor="modal-1" aria-hidden="true">
          <CloseIcon />
          </label>
        </div>
        <div className="modal--body">
          <p>{message}</p>
        </div>
      </div>
    </div>
  </div>
)

export default Modal;
