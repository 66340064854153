import React, { Component } from 'react';
import StatusReport from './StatusReport';

class App extends Component {
  render() {
    return (
      <div className="layout-container--sidebar">
        {/* Sidebar navigation */}
        {/* Trigger for mobile */}
        <input type="checkbox" className="hamburger--trigger" id="hamburger--trigger" />
        {/* Trigger for collapse */}
        <input type="checkbox" className="nav--trigger" id="collapse--trigger" />
        <nav className="sidebar color-dark">
          <label htmlFor="hamburger--trigger" className="hamburger--trigger-label">
            <div className="hamburger">
              <div>
                <span></span><span></span><span></span><span></span>
              </div>
            </div>
          </label>
          <div className="nav-content">
            <span className="sidebar-header color-brand-blue-x-dark-brand-3">
              <span className="nav-header--text__multiline">Greyhound</span>
            </span>
            <div className="nav-content--inner">
              <div className="sub-branding sidebar-list--ender">Sub-branding</div>
              <ul className="sidebar-list sidebar-list--ender">
                <li className="nav-item">
                  <span title="First Item" className="sidebar-link">
                    <span className="navigation--text">Status Reports</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* Main content wrapper */}

        <div className="content-container color-gray-x-light">
          <section className="page-container">
            <StatusReport />
          </section>
        </div>
      </div>
    );
  }
}

export default App;
