import React, { Component } from 'react';

import Modal from './components/Modal';

const domain = process.env.NODE_DOMAIN || '';
const RE_COPILOT_LINK = /^https:\/\/(?:[a-z]+-)?copilot\.condenast\.io\/([^/]+)\/([^/]+)\/([^/]+)\/?/;

class StatusReport extends Component {
  constructor() {
    super();
    this.state = {
      results: []
    };
  }
  async handleSubmit(evt) {
    const { value } = this.copilotLinksInput;
    const nonMatch = [];
    let descriptors = value.split('\n').reduce((acc, line) => {
      const url = line.trim();
      const matches = RE_COPILOT_LINK.exec(url);
      if (matches) {
        const [, brand, collection, id] = matches;
        acc.push({ brand, collection, id });
      } else {
        nonMatch.push(url);
      }

      return acc;
    }, []);

    // Try to get info from Brand API
    if (nonMatch.length > 0) {
      const webLinkDescriptor = await Promise.all(nonMatch.map(async (webUrl) => {
        const { brand, collection, id } = await getWeblinkFromApi(webUrl);
        return id
          ? { brand, collection, id }
          : { message: `Could not find info for ${webUrl}`};
      }));
      // Add array elements to other links and remove non Conde results
      descriptors = descriptors.concat(webLinkDescriptor);
    }


    const results = await Promise.all(descriptors.map(async ({ id, message }) => {
      if (id) {
        const res = await fetch(`${domain}/newsbundle?copilotId=${id}`);
        return res.json();
      } else {
        return { message, statusCode: 404 }
      }
    }));
    this.setState({ results })
  }
  render() {
    return (
      <React.Fragment>
        <div className="card color-white">
          <div className="card__header">
            <h2 className="card__label">Status Reports</h2>
          </div>
          <div className="card__body">
            <label htmlFor="body-text">Copilot Links</label>
            <textarea ref={copilotLinks => this.copilotLinksInput = copilotLinks} name="Body Text" id="body-text" cols="30" rows="3"></textarea>
            <p className="form-help">Paste one URL per line</p>
            <button className="button button--primary" onClick={evt => this.handleSubmit(evt)}>submit</button>
          </div>
        </div>
        <ResultsTable results={this.state.results} />
      </React.Fragment>
    );
  }
}

const ResultsTableRow = ({ row }) => {
  let result;

  // If something goes wrong when fetching a row, display the response error message.
  if (row.statusCode === 404) {
    result = (
      <td className="td color-danger-light">
        {row.message}
      </td>
    )
  } else if (row.copilotId) {
    result = (
      <React.Fragment>
        <td className="td">{row.copilotId}</td>
        <td className="td">
          <StatusCell row={row} />
        </td>
        <td className="td">
          {row.appleShareUrl && <a href={row.appleShareUrl}>{row.appleShareUrl}</a>}
        </td>
      </React.Fragment>
    )
  }
  return (
    <tr className="tr" key={row.id}>
      {result}
    </tr>
  )
}

const ResultsTable = ({ results = [] }) => {
  if (!results.length) {
    return null;
  }
  return (
    <div className="card color-white">
      <div className="card__header">
        <h2 className="card__label">Results</h2>
      </div>
      <div className="card__body">
        <div className="table-wrapper table--embedded">
          <table className="table">
            <thead className="thead">
              <tr className="tr">
                <th className="th">Copilot ID</th>
                <th className="th">Status</th>
                <th className="th">Share URL</th>
              </tr>
            </thead>
            <tbody className="tbody">
              {results.map((row, i) => <ResultsTableRow row={row} key={i} />)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

const StatusCell = ({ row }) => {
  let cellOutput;
  if (row.status === 'errored') {
    cellOutput = (
      <React.Fragment>
        <label htmlFor="modal-1" className="button button--warning">{row.status}</label>
        <Modal title={row.status} message={row.error} />
      </React.Fragment>
    )
  } else {
    cellOutput = row.status
  }
  return cellOutput;
}

async function getWeblinkFromApi (webUrl) {
  let apiDocument;
  try {
    apiDocument = await fetch(`copilotWebLink?webLink=${webUrl}`);
  } catch {
    return {};
  }
  return apiDocument.json();
}

export default StatusReport;
