import React from 'react';

const CloseIcon = () => (
    <svg className="SVGInline icon icon-large" width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
      <g id="close" stroke="none" stroke-width="1" fill-rule="evenodd">
        <path d="M12.2928932,2.29289322 C12.6834175,1.90236893 13.3165825,1.90236893 13.7071068,2.29289322 C14.0976311,2.68341751 14.0976311,3.31658249 13.7071068,3.70710678 L3.70710678,13.7071068 C3.31658249,14.0976311 2.68341751,14.0976311 2.29289322,13.7071068 C1.90236893,13.3165825 1.90236893,12.6834175 2.29289322,12.2928932 L12.2928932,2.29289322 Z" id="Stroke-31" fill-rule="nonzero"/>
        <path d="M2.29289322,3.70710678 C1.90236893,3.31658249 1.90236893,2.68341751 2.29289322,2.29289322 C2.68341751,1.90236893 3.31658249,1.90236893 3.70710678,2.29289322 L13.7071068,12.2928932 C14.0976311,12.6834175 14.0976311,13.3165825 13.7071068,13.7071068 C13.3165825,14.0976311 12.6834175,14.0976311 12.2928932,13.7071068 L2.29289322,3.70710678 Z" id="Stroke-33" fill-rule="nonzero"/>
      </g>
    </svg>
  )

  export default CloseIcon;